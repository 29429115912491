<template>
<aside class="ol-product-content__sidebar">
    <div v-for="i in qty" :key="i" class="ol-product-content__sidebar-item">
        <h4 class="ol-product-content__sidebar-item-title" style="height:25px">
            <SkeletonLoader/>
        </h4>
        <div class="ol-product-content__sidebar-item-list">
            <div class="ol-product-content__sidebar-item-list-item checked">
                <div class="ol-product__filter-list__item-dd"  style="height:30px">
                    <SkeletonLoader/>
                </div>
                <div class="ol-product__filter-list__item-dd"  style="height:30px;margin-top:5px">
                    <SkeletonLoader/>
                </div>
                <div class="ol-product__filter-list__item-dd"  style="height:30px;margin-top:5px">
                    <SkeletonLoader/>
                </div>
                <div class="ol-product__filter-list__item-dd"  style="height:30px;margin-top:5px">
                    <SkeletonLoader/>
                </div>
                <div class="ol-product__filter-list__item-dd"  style="height:30px;margin-top:5px">
                    <SkeletonLoader/>
                </div>
            </div>
        </div>
    </div>
</aside>
</template>

<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"Sidebar",
    components:{
        SkeletonLoader
    },
    data(){
        return{
            qty:4,
        }
    },

}
</script>
