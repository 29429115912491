<template>
    <BaseLayout>
        <img  class="image-home-info banner-desktop" width="100%" :src="require('@/assets/images/kasur/laxy-2130X900.jpg')" />
        <div class="ol-wrapper ol-wrapper--large">
            <div class="break-32 banner-desktop"></div>
            <h1 class="text-header">Springbed Dalam Box</h1>
            <div class="break-16"></div>
            <p style="text-align:center;" class="text-title">
                Perkenalkan Laxy Bed. Springbed berteknologi khusus yang menggunakan proses vacuum sehingga muat ke dalam box, membuat belanja springbed kini jadi lebih praktis. Dilengkapi juga dengan fitur-fitur berkualitas untuk ciptakan tidur yang makin relax.
 
            </p>
            <div class="break-32"></div>
        </div>
        <img  class="image-home-info banner-mobile" width="100%" :src="require('@/assets/images/kasur/laxy-800X800.jpg')" />
        <div style="background-color:#E3EEF7;margin-top:32px;margin-bottom: 32px;">
            
            
                <div class="break-32"></div>
                <div class="row no-gutters info-kelebihan">
                    <div class="col-3">
                        <div class="row no-gutters" style="text-align:center">
                            <div class="col-lg-12"><img  class="image-home-info image-kelebihan" :src="require('@/assets/images/benefit/laxy-03.png')" /></div>
                            <div class="col-lg-12 text-title">Pegas Pocketed Spring</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="row no-gutters" style="text-align:center">
                            <div class="col-lg-12"><img  class="image-home-info image-kelebihan" :src="require('@/assets/images/benefit/laxy-02.png')" /></div>
                            <div class="col-lg-12 text-title">100% Gratis Ongkir</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="row no-gutters" style="text-align:center">
                            <div class="col-lg-12"><img  class="image-home-info image-kelebihan" :src="require('@/assets/images/benefit/laxy-01.png')" /></div>
                            <div class="col-lg-12 text-title">Garansi Hingga 10 Tahun</div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="row no-gutters" style="text-align:center">
                            <div class="col-lg-12"><img  class="image-home-info image-kelebihan" :src="require('@/assets/images/benefit/laxy-05.png')" /></div>
                            
                            <div class="col-lg-12 text-title">Bisa Cicilan Hingga 12x</div>
                        </div>
                    </div>
                </div>
                <div class="break-32"></div>
            
        </div>
        <div class="ol-wrapper ol-wrapper--large">
            <ProductList :products="products" /> 
            <div class="break-32"></div>
        </div>
        <div class="ol-wrapper ol-wrapper--large">
            <div class="row" style="text-align: center;font-weight: bold;" >
                <div class="text-subtitle" style="margin:auto">
                    <p>Kenal Lebih Dekat Dengan Laxy Bed</p>
                    <div class="break-16"></div>
                    <a target="_blank" href="https://www.instagram.com/laxy.bed/"><img height="40px" :src="require('@/assets/images/kasur/followus.png')"></a>
                </div>
            </div>
            <div class="break-32"></div>
            <div class="row" style="text-align: center;" >
                <div class="animation text-subtitle">
                    <p>Kasur favorit  <span class="typed-text">{{ typedText }}</span><span class="cursor" :class="{ typing: isTyping }"></span></p>
                    
                </div>
            </div>
            <div class="break-16"></div>
            <TestimonialLaxy />
            <iframe width="100" class="iframe-youtube"  src="https://www.youtube.com/embed/9RaBFfMcjTM?si=dBH389QXYP0SOEcR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div style="background-color:#E3EEF7;margin-top:32px;margin-bottom: 32px;">
            <div class="ol-wrapper ol-wrapper--large">
                <div class="break-32"></div>
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <p style="text-align: center;margin:auto;" class="text-subtitle">
                            <b>FAQ</b>
                        </p>
                        <div class="break-16"></div>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="accordion">
                            <div class="accordion-item">
                                <button id="accordion-button-0" class="accordion" :aria-expanded="items[0].expanded" @click="toggleAccordion(0)">
                                <span class="accordion-title">Apakah Laxy Bed & Rolly Foam dapat dikempeskan kembali untuk masuk ke dalam Box?</span><span class="icon" aria-hidden="true"></span>
                                </button>
                                <div class="accordion-content">
                                    <p>
                                        Setelah dibuka, springbed laxy akan mengembang beserta pegasnya. Begitu juga dengan Kasur Rolly yang setelah dibuka akan mengembang dengan sempurna sehingga tidak bisa dikempeskan kembali untuk masuk ke dalam box.
                                    </p>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <button id="accordion-button-1" class="accordion" :aria-expanded="items[1].expanded" @click="toggleAccordion(1)">
                                <span class="accordion-title">Berapa lama proses mengembang Laxy Bed?</span><span class="icon" aria-hidden="true"></span>
                                </button>
                                <div class="accordion-content">
                                    <p>Laxy bed membutuhkan waktu 72 jam untuk mengembang secara sempurna. Namun, dalam waktu menunggu proses springbed mengembang secara sempurna kamu tetap bisa tidur diatas springbed Laxy.
                                    </p>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <button id="accordion-button-2" class="accordion" :aria-expanded="items[2].expanded" @click="toggleAccordion(2)">
                                <span class="accordion-title">Bagaimana cara perawatan Laxy Bed & Rolly Foam?</span><span class="icon" aria-hidden="true"></span>
                                </button>
                                <div class="accordion-content">
                                    <p>Meski bergaransi dan berkualitas, kamu juga perlu memperhatikan perawatan Laxy Bed & Rolly Foam seperti berikut:<br>
                                        <ul style="list-style: circle;margin-left: 18px;">
                                            <li>Usahakan kasur tidak langsung bersentuhan dengan lantai, beri divan / karpet sebagai alas.</li>
                                            <li>Putar posisi kasur secara horizontal setiap 6 bulan agar lebih awet dan tahan lama.</li>
                                            <li>Bersihkan kasur secara berkala agar higenis dan terhindar dari debu.</li>
                                            <li>Hindari kasur terkena banjir, air seni, tumpahan makanan, dan cairan lainnya.</li>
                                            <li>Jika ada kerusakan pada kasur, harap tidak direparasi oleh pihak luar selain pihak brand.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="break-32"></div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large">
            <div >
                
            </div>
        </div>
    </BaseLayout>
</template>

<script>
import {mapActions,mapGetters} from "vuex";
import BaseLayout from "@/components/landing/BaseLayout.vue"
import ProductList from "@/components/product/ProductList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
import SidebarLoader from '@/components/loading/product/Sidebar.vue'
import TestimonialLaxy from "@/components/home/TestimonialLaxy.vue";
import Videoshort from "@/components/home/Videoshort.vue";
export default {
    name:"CategoryLaxy",
    components:{
        BaseLayout,
        ProductList,
        ProductLoader,
        TestimonialLaxy,
        Videoshort,
        SidebarLoader
    },
    data(){
        return {
            typedText: "",
            textArray: ["anak-anak", "ayah dan ibu", "keluarga Indonesia"],
            typingDelay: 200,
            erasingDelay: 100,
            newTextDelay: 1000,
            textArrayIndex: 0,
            charIndex: 0,
            isTyping: false,
            items: [
                { expanded: false },
                { expanded: false },
                { expanded: false },
                // ... Add more items as needed
            ],
            filterCategories: "",
            filterSearch: "",
            loading:true,
            loadingSidebar:true,
            loadingRelated: false,
            list:{
                more:false,
                total:0,
            },
            statusReset:false,
            filteredBy:this.$store.state.product.list.filtered,
        }
    },
    computed:{
        ...mapGetters("product/list",["products","categories","filterParameters","filtered","categoryGroup","copiedFilter"]),
    },
    methods:{
        ...mapActions("product/list",["fetchProducts","fetchFilterParameters"]),
        filterProduct() {
            this.getProducts(true);
            // console.log();
        },
        async getProducts(firstPage = false,per_page=null){
            this.loading = true;

            if(this.copiedFilter && this.$route.params.filter) this.retrieveCopiedFilter();
            
            var categories = this.filterCategories;
            var search = this.filterSearch;
            
            this.filteredBy.q = "laxy";
            this.$store.commit("product/list/setFiltered",this.filteredBy);

            const results = await this.fetchProducts();
            this.statusReset = false;
            this.loading = false;
            if(results.success){
                this.list.total = results.data.total; 
                this.list.more = results.data.pagination.more;
                if(this.list.more) {
                    this.filteredBy.page=this.filteredBy.page+1;
                    this.$store.commit("product/list/setFiltered",{page:this.filteredBy.page});
                }
            }else{
                this.$toast.open({ message: "Gagal mendapatkan data produk", type: "error", duration: 3000, })
            }
            
        },
        type() {
        if (this.charIndex < this.textArray[this.textArrayIndex].length) {
            this.isTyping = true;
            this.typedText += this.textArray[this.textArrayIndex].charAt(this.charIndex);
            this.charIndex++;
            setTimeout(this.type, this.typingDelay);
        } else {
            this.isTyping = false;
            setTimeout(this.erase, this.newTextDelay);
        }
        },
        erase() {
        if (this.charIndex > 0) {
            this.isTyping = true;
            this.typedText = this.textArray[this.textArrayIndex].substring(0, this.charIndex - 1);
            this.charIndex--;
            setTimeout(this.erase, this.erasingDelay);
        } else {
            this.isTyping = false;
            this.textArrayIndex++;
            if (this.textArrayIndex >= this.textArray.length) this.textArrayIndex = 0;
            setTimeout(this.type, this.typingDelay + 1100);
        }
        },
        toggleMenu(selectorName){
            $(selectorName).slideToggle("fast");
        },
        toggleMenuWithSelector(targetSelector){
            targetSelector.slideToggle("fast");
        },
        toggleAccordion(index) {
            for (let i = 0; i < this.items.length; i++) {
                this.items[i].expanded = false;
            }
            this.items[index].expanded = !this.items[index].expanded;
        },
    },
    async mounted(){
        await Promise.all([
            this.getProducts(true),
        ]);
        setTimeout(this.type, this.newTextDelay + 250);

        document.title = 'Beli Kasur Springbed Laxy Bed in Box | MORE Furniture';
        document.description = 'Beli kasur springbed pocket spring Laxy Bed in the box (Dalam box) dengan ukuran 160 dan 180. Bergaransi 10 tahun dan pasti gratis ongkir!';
    },
    beforeUpdate(){
        if($('.ol-product-type__list').hasClass("slick-initialized slick-slider")){
            $('.ol-product-type__list').slick('unslick');
        }
    },
}
</script>

<style scoped lang="css">

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 8px 0;
  /* color: #364B7B; */
  font-size: 16px;
  font-weight: bold;
  border: none;
  background: none;
  outline: none;
}
.accordion button:hover, .accordion button:focus {
  cursor: pointer;
  color: #364B7B;
}
.accordion button:hover::after, .accordion button:focus::after {
  cursor: pointer;
  color: #364B7B;
  border: 1px solid #364B7B;
}
.accordion button .accordion-title {
  /* padding: 1em 1.5em 1em 0; */
  display:inline-block;
  width:90%;
}
.accordion button .icon {
  display: inline-block;
  position: absolute;
  /* top: 18px; */
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}
.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}
.accordion button[aria-expanded=true] {
  color: #364B7B;
}
.accordion button[aria-expanded=true] .icon::after {
  width: 0;
}
.accordion button[aria-expanded=true] + .accordion-content {
  opacity: 1;
  max-height: 100%;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 16px;
}


.animation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto
}
.animation p {
 
  
  font-weight: bold;
  /* letter-spacing: 0.1rem; */
  text-align: center;
  overflow: hidden;
}
.animation p span.typed-text {
  font-weight: bold;
  color: #364b7b;
}
.animation p span.cursor {
  display: inline-block;
  /* background-color: #ccc; */
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}
.animation p span.cursor.typing {
  animation: none;
}
@keyframes blink {
  0%  { background-color: #ccc; }
  49% { background-color: #ccc; }
  50% { background-color: transparent; }
  99% { background-color: transparent; }
  100%  { background-color: #ccc; }
}


.info-sertifikat{
    width:50%;
    margin:auto;
}

.info-kelebihan{
    width:70%;
    margin:auto;
}
.text-title{
    font-size:18px;
}

.text-subtitle{
    font-size:24px;
}

.text-header{
    font-size:30px;font-weight: bold;text-align:center;
}

.image-kelebihan{
    width:30%;
}

.image-sertifikat{
    height:100px;
}
.image-sertifikat-1{
    height: 55px;
}

.banner-desktop{
    display:block;
}

.banner-mobile{
    display:none;
}

@media(max-width:767px){
    .info-sertifikat{
        width:100%;    
    }

    .info-kelebihan{
        width:100%;
        margin:auto;
    }

    .text-title{
        font-size:14px;
    }

    .text-subtitle{
        font-size:16px;
    }

    .text-header{
        font-size:20px;font-weight: bold;text-align:center;
    }

    .image-kelebihan{
        width:50%;
    }

    .image-sertifikat{
        height: 60px;
    }

    .image-sertifikat-1{
        height: 35px;
    }

    .banner-desktop{
        display:none;
    }

    .banner-mobile{
        display:block;
    }
}

.iframe-youtube{
    height: 515px;
    width:100%;
}

@media(max-width:767px){
  .iframe-youtube{
      height: 315px;
      width:100%;
  }
}
</style>