<template>
      <div v-if="loading" class="container-endorsement ol-home-endorsement-list">
        <SkeletonLoader />
      </div>
      <div v-else class="container-endorsement ol-home-endorsement-list">
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@blogyazid.jpg')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <div class="user-info">
                <h5 style="font-size:11px;">Blogyazid</h5>
                <small>@blogyazid</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@nueldesy.jpg')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <div class="user-info">
                
                <h5 style="font-size:11px;">Neueldesy</h5>
                <small>@nueldesy</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@bundaule.jpg')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <div class="user-info">
                <h5 style="font-size:11px;">Bundaule</h5>
                <small>@bundaule</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@Sekuat_tv.jpg')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <div class="user-info">
                <h5 style="font-size:11px;">Sekuat TV</h5>
                <small>@Sekuat_tv</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@talitamaghfira.jpg')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <div class="user-info">
                <h5 style="font-size:11px;">Talita Maghfira</h5>
                <small>@talitamaghfira</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card-endorsement" >
          <div class="card-endorsement-header">
              <img :src="require('@/assets/images/kasur/@Mommy_wongndeso.jpg')" />
          </div>
          <div class="card-endorsement-body">
            <div class="user">
              <div class="user-info">
                <h5 style="font-size:11px;">Mommy Wongndeso</h5>
                <small>@Mommy_wongndeso</small>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  import SkeletonLoader from "@/components/loading/skeletonLoader";
  import {mapActions,mapGetters} from "vuex";
  
  export default {
    name: "TestimonialLaxy",
    components:{
      SkeletonLoader
    },
    data() {
      return {
        loading:false
      };
    },
    async mounted() {
        $(".container-endorsement").slick({
            slidesToScroll: 1,
            slidesToShow: 5,
            dots: !1,
            autoplay: !0,
            autoplaySpeed: 5e3,
            arrows: !0,
            pauseOnFocus: !1,
            infinite: !0,
            speed: 500,
            fade: !1,
            responsive: [{
            breakpoint: 891,
            settings: {
                slidesToShow: 2,
            },
            }, {
            breakpoint: 550,
            settings: {
                slidesToShow: 2,
            },
            }],
        });
    },
    updated() {
      
    },
  };
  </script>
  
  <style lang="css" scoped>
  @import "../../assets/css/_endorsement.css";
  @import "../../assets/js/slick/slick.css";
  </style>
  