<template>
    <div v-if="loading" class="container-video  ol-home-endorsement-list">
      <SkeletonLoader />
    </div>
    <div v-else class="container-video  ol-home-endorsement-list">
      <div class="card-endorsement"  >
        <div class="card-endorsement-body" style="padding:8px;">
            <iframe width="100%" class="iframe-youtube" src="https://www.youtube.com/embed/7h23uOIuqG4?si=h_3K4fD594mksf5P" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
      <div class="card-endorsement"  >
        <div class="card-endorsement-body" style="padding:8px;">
            <iframe width="100%" class="iframe-youtube" src="https://www.youtube.com/embed/Vo8owdjiSKM?si=tWzo60fYUo0-5qF3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
</template>

<script>
import SkeletonLoader from "@/components/loading/skeletonLoader";
import {mapActions,mapGetters} from "vuex";

export default {
  name: "Videoshort",
  components:{
    SkeletonLoader
  },
  data() {
    return {
      loading:false
    };
  },
  async mounted() {
      $(".container-video").slick();
  },
  updated() {
    
  },
};
</script>

<style lang="css" scoped>
@import "../../assets/css/_endorsement.css";
@import "../../assets/js/slick/slick.css";
.iframe-youtube{
    height: 515px;
    width:100%;
}

@media(max-width:767px){
  .iframe-youtube{
      height: 315px;
      width:100%;
  }
}
</style>
